import React from 'react';
import {FC} from 'react';
import Can from 'features/hylian-shield/can';
import {Trash2} from 'react-feather';
import {useDispatch} from 'react-redux';
import {ProductWeb} from 'types/product-web';
import Button from 'components/button';
import {uiActions} from 'store/ui/ui-slice';
import {tableSlice} from 'features/table/slice';

interface Props {
  product: ProductWeb;
}

const ProductMaterWebActions: FC<Props> = ({product}) => {
  const dispatch = useDispatch();

  const destroyProductWeb = () => {
    dispatch(
      uiActions.confirmDelete({
        resourceId: product.id,
        name: 'product-webs',
        onDestroy: () =>
          dispatch(
            tableSlice.actions.destroyResource({
              resourceId: product.id,
              resourceName: 'product-webs',
            })
          ),
      })
    );
  };

  return (
    <div className="d-flex align-items-center h-100">
      <Can action="administration" subject="products">
        <Button color="transparent" tag="button" onClick={destroyProductWeb}>
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default ProductMaterWebActions;
