import React from 'react';
import messages from './messages';
import {useWebForm} from './use-web-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import Can from 'features/hylian-shield/can';
import AsyncItemPicker from 'components/async-item-picker';
import ItemPicker from 'components/item-picker';
import {LocalizedTextEditor} from 'components/text-editor/multi-lang';
import {LocalizedTextInput} from 'components/text-input/multi';
import {LANGUAGES} from 'constants/language';

const WebTab = () => {
  const {
    isLoading,
    params,
    setParams,
    submit,
    validationErrors,
    handleChangeProductWeb,
  } = useWebForm();

  const {t} = useTranslation();

  return (
    <div>
      <Row className="mb-3">
        <LocalizedTextInput
          languages={LANGUAGES}
          value={lang => params.subtitle[lang]}
          onChange={(value, lang) => {
            setParams(prev => ({
              ...prev,
              subtitle: {
                ...prev.subtitle,
                [lang]: value,
              },
            }));
          }}
          label={lang =>
            t(...messages.subtitle(), {
              lang: lang,
            })
          }
          errors={lang => validationErrors[`subtitle.${lang}`]}
        />
      </Row>
      <Row className="mb-3">
        <LocalizedTextEditor
          id={lang => `editor_${lang}`}
          languages={LANGUAGES}
          value={lang => params.description[lang]}
          onChange={(value, lang) => {
            setParams(prev => ({
              ...prev,
              description: {
                ...prev.description,
                [lang]: value,
              },
            }));
          }}
          label={lang =>
            t(...messages.description(), {
              lang: lang,
            })
          }
          errors={lang => validationErrors[`description.${lang}`]}
        />
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <AsyncItemPicker
            label={t(...messages.colors())}
            endPoint="data/colors-web"
            errors={validationErrors.colors}
            onChange={colors => setParams({...params, colors})}
            isMulti
            value={params.colors}
          />
        </Col>
        <Col md={6}>
          <AsyncItemPicker
            label={t(...messages.familleWeb())}
            errors={validationErrors.family_web_id}
            endPoint="data/families-web"
            onChange={family_web => setParams({...params, family_web})}
            value={params.family_web}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <AsyncItemPicker
            label={t(...messages.productWeb())}
            errors={validationErrors.product_web_id}
            endPoint="data/products-web"
            onChange={product_web => handleChangeProductWeb(product_web)}
            value={params.product_web}
          />
        </Col>
        <Col md={6}>
          <ItemPicker
            label={t(...messages.size())}
            errors={validationErrors.size_web_id}
            options={params.product_web?.size_webs ?? []}
            onChange={(size_web: any) => setParams({...params, size_web})}
            value={params.size_web}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <div className="form-check my-3">
            <input
              className="form-check-input "
              type="checkbox"
              onChange={() =>
                setParams({...params, is_price_fixed: !params.is_price_fixed})
              }
              id="is_price_fixed"
              checked={params.is_price_fixed}
            />
            <label className="form-check-label " htmlFor="is_price_fixed">
              {t(...messages.fixedPrice())}
            </label>
          </div>
        </Col>
        <Col md={3}>
          <div className="form-check my-3">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={() =>
                setParams({...params, is_published: !params.is_published})
              }
              id="is_published"
              checked={params.is_published}
            />
            <label className="form-check-label" htmlFor="is_published">
              {t(...messages.published())}
            </label>
          </div>
        </Col>
      </Row>
      <Can action="edit" subject="products">
        <Row>
          <Col md={12} className=" mb-4">
            <div className="mt-3">
              <AppBarActions>
                <Button
                  isLoading={isLoading}
                  color="important"
                  onClick={submit}
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
              </AppBarActions>
            </div>
          </Col>
        </Row>
      </Can>
    </div>
  );
};

export default WebTab;
