import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {useUpdateVariantWebInfoMutation} from 'store/production/endpoints/update-variant-web-info';
import {ProductVariant} from 'types/product-variant';
import toast from 'store/ui/actions/toast';
import {Option} from 'types/option';
import {ProductWeb} from 'types/product-web';
import {SizeWeb} from 'types/size-web';
import { Language } from 'components/text-editor/multi-lang/types';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useWebForm = () => {
  const [updateVariantWeb, {isLoading, error, data: response}] =
    useUpdateVariantWebInfoMutation();

  const serverError = parseValidationErrors(error);

  const {variant: id, id: productId} = useParams();

  const {data} = useSelector(selectVariant({id, productId}));

  const variant = data?.data as ProductVariant;
  const variantWeb = variant.product_variant_web;

  const [params, setParams] = useState<Params>({
    subtitle: {
      fr: variantWeb?.subtitle?.fr ?? '',
      en: variantWeb?.subtitle?.en ?? '',
    },
    description: {
      en: variantWeb?.description?.en ?? '',
      fr: variantWeb?.description?.fr ?? '',
    },
    colors: variantWeb?.colors_web,
    family_web: variantWeb?.family_web as Option,
    product_web: variantWeb?.product_web,
    size_web: variantWeb?.size_web,
    is_price_fixed: variantWeb.is_price_fixed ?? true,
    is_published: variant.is_published ?? false,
  });

  const handleInputValue = (lang: Language) => {
    return  params.subtitle[lang];
  }

  const handleInputChange = (value: string, lang: Language) => {
    setParams((prev) => ({
      ...prev,
      subtitle: {
        ...prev.subtitle, 
        [lang]: value,       
      },
    }));
  };

  const handleEditorValue = (lang: Language) => {
    return  params.description[lang];
  }

  const handleEditorChange = (value: string, lang: Language) => {
    setParams((prev) => ({
      ...prev,
      description: {
        ...prev.description, 
        [lang]: value,       
      },
    }));
  };

  const [sizeOptions, setSizeOptions] = useState([]);

  const dispatch = useDispatch();

  const handleChangeProductWeb = (product_web: ProductWeb) => {
    if (product_web?.id === params.product_web?.id) return;
    setParams({
      ...params,
      product_web,
      size_web: {} as SizeWeb,
    });
  };

  const handleSubmit = async () => {
    const result = await updateVariantWeb({
      id: productId,
      variant: id,
      subtitle: {
        fr: params.subtitle.fr,
        en: params.subtitle.en,
      },
      description: {
        fr: params.description.fr,
        en: params.description.en,
      },
      colors: params.colors.map(el => el.id),
      family_web_id: params.family_web?.id,
      product_web_id: params.product_web?.id,
      size_web_id: params.size_web?.id,
      is_price_fixed: params.is_price_fixed,
      is_published: params.is_published,
    });

    if ('data' in result) {
      redirectByVariant(result.data.data);
      dispatch(toast('success', result.data.message));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const redirectByVariant = (v: ProductVariant) => {
    searchParams.set(
      'filter[product_variant_category_name]',
      String(v.product_variant_category?.name)
    );
    const newParams = searchParams.toString();
    navigate(
      `/dashboard/products/${v.product_id}/variants/${v.id}/web?${newParams}`
    );
  };

  return {
    setParams,
    submit: handleSubmit,
    setSizeOptions,
    handleChangeProductWeb,
    sizeOptions,
    variantName: variant?.name,
    isLoading,
    params,
    validationErrors: serverError.errors,
    handleEditorValue,
    handleEditorChange,
    handleInputValue,
    handleInputChange
  };
};

type Params = {
  subtitle: {
    fr: string;
    en: string;
  };
  description: {
    fr: string,
    en: string
  }
  colors: Option[];
  family_web: Option;
  product_web: ProductWeb;
  size_web: SizeWeb;
  is_price_fixed: boolean;
  is_published: boolean;
};
