import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductWeb} from 'types/product-web';
import {ResponseData} from 'types/response-data';

const showProductWeb = api.injectEndpoints({
  endpoints: builder => ({
    showProductWeb: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `product-webs/${params.id}`,
      }),
      // @ts-expect-error
      providesTags: result => {
        if (result) {
          return [{type: 'product-webs', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<ProductWeb>;

export const {useShowProductWebQuery} = showProductWeb;
export const selectProductWeb = showProductWeb.endpoints.showProductWeb.select;
