import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  subtitle: () => __(translations.webTabPage.subtitle, 'Subtitle ({{lang}})'),
  description: () => __(translations.webTabPage.description, 'Description ({{lang}})'),
  colors: () => __(translations.webTabPage.colors, 'Colors'),
  familleWeb: () => __(translations.webTabPage.familleWeb, 'Family Web'),
  productWeb: () => __(translations.webTabPage.productWeb, 'Product Web'),
  size: () => __(translations.webTabPage.size, 'Size'),
  fixedPrice: () => __(translations.webTabPage.fixedPrice, 'Fixed price (if no, a mention starting from wil be displayed)'),
  published: () => __(translations.webTabPage.published, 'Product Is Published')
};
