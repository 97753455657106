import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  actions: () => __(translations.editProductPage.actions, 'Actions'),
  attachments: () => __(translations.editProductPage.attachments, 'Attachments'),
  catalog: () => __(translations.editProductPage.catalog, 'Catalog'),
  collectionFilterDefault: () => __(translations.editProductPage.collectionFilterDefault, 'Collection'),
  category: () => __(translations.editProductPage.category, 'Category'),
  collectionSheet: () => __(translations.editProductPage.collection, 'Collection sheet'),
  color: () => __(translations.editProductPage.color, 'Color'),
  comment: () => __(translations.editProductPage.comment, 'Comment'),
  components: () => __(translations.editProductPage.components, 'Components'),
  costs: () => __(translations.editProductPage.costs, 'Costs'),
  costSheet: () => __(translations.editProductPage.cost, 'Cost sheet '),
  createdAt: () => __(translations.editProductPage.createdAt, 'Created at'),
  stockMin: () => __(translations.editProductPage.stockMin, 'Stock min'),
  stockMax: () => __(translations.editProductPage.stockMax, 'Stock max'),
  createVariant: () => __(translations.editProductPage.createVariant, 'Add Variant'),
  customizationOptions: () => __(translations.editProductPage.customizationOptions, 'Customization options'),
  description: () => __(translations.editProductPage.description, 'Description'),
  dimension: () => __(translations.editProductPage.dimension, 'Dimension (cm) (H x L x P)'),
  editProduct: () => __(translations.editProductPage.editProduct, 'Edit Product'),
  finition: () => __(translations.editProductPage.finition, 'Finition'),
  general: () => __(translations.editProductPage.general, 'General'),
  isArchived: () => __(translations.editProductPage.isArchived, 'Archived'),
  isBlocked: () => __(translations.editProductPage.isblocked, 'Blocked'),
  isoldCollection: () => __(translations.editProductPage.isoldCollection, 'Old collection'),
  label: () => __(translations.editProductPage.label, 'label'),
  limited: () => __(translations.editProductPage.limited, 'Limited edition'),
  selfComposed: () => __(translations.editProductPage.selfComposed, 'Product without compenents'),
  published: () => __(translations.updateGeneralTabPage.published, 'Product Is Published'),
  linkedResources: () => __(translations.editProductPage.linkedResources, 'Linked Resources'),
  manifacturingTime: () => __(translations.editProductPage.manifacturingTime, 'Manifacturing Time'),
  masterProduct: () => __(translations.editProductPage.masterProduct, 'Master Product'),
  matter: () => __(translations.editProductPage.matter, 'Matter'),
  options: () => __(translations.editProductPage.options, 'Options'),
  other: () => __(translations.editProductPage.other, 'Other'),
  pageTitle: () => __(translations.editProductPage.pageTitle, 'Edit Product'),
  productsTitle: () => __(translations.editProductPage.productsTitle, 'Products'),
  serialNumber: () => __(translations.editProductPage.serialNumber, 'Serial Number'),
  size: () => __(translations.editProductPage.size, 'Size'),
  stats: () => __(translations.editProductPage.stats, 'Stats'),
  stock: () => __(translations.editProductPage.stock, 'Stock'),
  technicalSheetClient: () => __(translations.editProductPage.technicalSheetClient, 'technical sheet client'),
  technicalSheetInterne: () => __(translations.editProductPage.technicalSheetInterne, 'technical sheet interne'),
  translations: () => __(translations.editProductPage.translations, 'Translations'),
  variants: () => __(translations.editProductPage.variants, 'Variants'),
  ecoTax: () => __(translations.editProductPage.ecoTax, 'Eco-tax'),
  web: () => __(translations.editProductPage.web, 'Web'),
};
