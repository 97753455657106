import React from 'react';
import ProductMasterWebActions from './master-product-web-actions';
import EmptyCell from 'components/empty-cell';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/react-table';
import {ProductWeb} from 'types/product-web';

const columns: ColumnDef<ProductWeb>[] = [
  {
    header: r => <Trans i18nKey="productWebPage.name" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/master-products-web/${row.id}/edit`}
        target="_blank"
      >
        <EmptyCell cell={row.name?.toLocaleUpperCase()} />
      </Link>
    ),
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    id: 'name',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="productWebPage.actions" />,
    accessorFn: row => <ProductMasterWebActions product={row} />,
    id: 'actions',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    enableSorting: false,
  },
];

export default columns;
