import {api} from 'services/api';
import {Product} from 'types/product';
import {ResponseData} from 'types/response-data';

const storeProductWeb = api.injectEndpoints({
  endpoints: builder => ({
    storeProductWeb: builder.mutation<Response, StoreProductWebRequest>({
      query: body => {
        return {
          method: 'POST',
          url: `product-webs`,
          body,
        };
      },
    }),
  }),
});

export type StoreProductWebRequest = {
  name_fr: ''
  name_en: ''
  skill_fr: ''
  skill_en: ''
  reassurance_information_fr: ''
  reassurance_information_en: ''
  sizes: {
    fr: string;
    en: string;
  }[];
};

type Response = ResponseData<Product>;

export const {useStoreProductWebMutation} = storeProductWeb;
