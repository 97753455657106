import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  action: () => __(translations.productsPage.action, 'Action'),
  actionMultipleButton: () => __(translations.productsPage.actionMultipleButton, 'Action multiple'),
  addProduct: () => __(translations.productsPage.addProduct, 'Add product'),
  all: () => __(translations.productsPage.all, 'All'),
  archive: () => __(translations.productsPage.archive, 'Archive'),
  archiveAlertMessage: () => __(translations.productsPage.archiveAlertMessage, 'are you sure you want to archive this variant?'),
  available: () => __(translations.productsPage.available, 'Available'),
  catalog: () => __(translations.productsPage.catalog, 'Catalog'),
  category: () => __(translations.productsPage.category, 'Category'),
  color: () => __(translations.productsPage.color, 'Colors'),
  costsByType: () => __(translations.productsPage.CostsByType, 'Costs by type'),
  deleteAlertMessage: () => __(translations.productsPage.deleteAlertMessage, 'are you sure you want to delete this variant?'),
  details: () => __(translations.productsPage.details, 'Details'),
  dimensions: () => __(translations.productsPage.dimensions, 'Dimensions'),
  edit: () => __(translations.productsPage.edit, 'Edit'),
  exports: () => __(translations.productsPage.exports, 'Exports'),
  families: () => __(translations.productsPage.familie, 'Familie'),
  finish: () => __(translations.productsPage.finish, 'Finish'),
  forSale: () => __(translations.productsPage.forSale, 'For sale'),
  furnitureCosts: () => __(translations.productsPage.furnitureCosts, 'Furniture costs'),
  inCatalog: () => __(translations.productsPage.inCatalog, 'In catalog'),
  inStock: () => __(translations.productsPage.instock, 'Stock'),
  isArchived: () => __(translations.productsPage.isArchived, 'include archived products'),
  isBlocked: () => __(translations.productsPage.isblocked, 'include blocked products'),
  isoldCollection: () => __(translations.productsPage.isoldCollection, 'include old collection products'),
  listOfCosts: () => __(translations.productsPage.listOfCosts, 'List of Coast'),
  masterProduct: () => __(translations.productsPage.masterProduct, 'Master product'),
  material: () => __(translations.productsPage.material, 'Material'),
  matter: () => __(translations.productsPage.matter, 'Matters'),
  name: () => __(translations.productsPage.name, 'Name'),
  notAvailable: () => __(translations.productsPage.notAvailable, 'Not available'),
  notForSale: () => __(translations.productsPage.notForSale, 'Not for sale'),
  notInCatalog: () => __(translations.productsPage.notInCatalog, 'Not in catalog'),
  outCatalog: () => __(translations.productsPage.outCatalog, 'Out of catalog'),
  clearSelection: () => __(translations.productsPage.clearSelection, 'Clear selection'),
  pageTitle: () => __(translations.productsPage.pageTitle, 'Products'),
  preview: () => __(translations.productsPage.preview, 'Preview'),
  pricing: () => __(translations.productsPage.pricing, 'Edit pricing'),
  products: () => __(translations.productsPage.products, 'Products'),
  productsList: () => __(translations.productsPage.productsList, 'Products list'),
  productsTotal: () => __(translations.productsPage.productsTotal, 'Total products'),
  project: () => __(translations.productsPage.project, 'Project'),
  putInCatalog: () => __(translations.productsPage.putInCatalog, 'Put in catalog'),
  size: () => __(translations.productsPage.size, 'Sizes'),
  sku: () => __(translations.productsPage.sku, 'SKU'),
  smallItemCosts: () => __(translations.productsPage.smallItemCosts, 'Small item costs'),
  stockAvailable: () => __(translations.productsPage.stockAvailable, 'Stock Available/Total'),
  stockDispo: () => __(translations.productsPage.stockDispo, 'Stock available'),
  subFamilies: () => __(translations.productsPage.subFamilies, 'Sub-families'),
  supplier: () => __(translations.productsPage.supplier, 'Suppliers'),
  total: () => __(translations.productsPage.stockTotal, 'Total stock'),
  totalExclTaxes: () => __(translations.productsPage.totalExclTaxes, 'Total Excl Taxes'),
  delete: () => __(translations.productsPage.delete, 'Delete'),
  totalInclTaxes: () => __(translations.productsPage.totalInclTaxes, 'Total Incl Taxes'),
  totalProduct: () => __(translations.productsPage.totalProduct, 'Total products For sale'),
  masterProducts: () => __(translations.productsPage.masterProducts, 'Master products'),
  masterProductsWeb: () => __(translations.productsPage.masterProductsWeb, 'Master products web'),
};
