import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  pageTitle: () => __(translations.productWebPage.pageWebTitle, 'Master products web'),
  masterProductsWeb: () => __(translations.productWebPage.masterProductsWeb, 'Master products web'),
  name: () => __(translations.productWebPage.name, 'Name'),
  actions: () => __(translations.productWebPage.actions, 'Actions'),
  addMasterProductWeb: () => __(translations.productWebPage.addMasterProductWeb, 'Add master products web'),
};
