import {ProjectPhasesEnum} from 'features/studio/projects/constants/project-phases';
import {
  EnumMapping,
  useEnumAsOption,
} from 'features/studio/projects/utils/use-enum-as-option';
import {useEffect, useMemo, useState} from 'react';
import {
  GetPhaseProgression,
  HandleAddDeposit,
  HandleAddInput,
  HandleChangeDeposit,
  HandleChangeGlobalComment,
  HandleInputChange,
  HandleRemoveDeposit,
  HandleRemoveInput,
  HandleSubmit,
} from './types';
import {Option} from 'types/option';
import {ObjectId} from 'types/object-id';
import {IDeposit, IPhase, Project} from 'types/project';
import {
  StoreProjectPhasesRequest,
  useStoreProjectPhasesMutation,
} from 'features/studio/projects/store/store-project-phases';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import makeId from 'utils/make-id';
import {useSelector} from 'react-redux';
import {selectProject} from 'features/studio/projects/store/show-project';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ValidationErrors} from 'services/api/types';
import {DropResult} from 'react-beautiful-dnd';
import arrayReorder from 'utils/array-reorder';
import orderingPhases from 'features/studio/projects/utils/ordering-phases';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {Subject} from 'features/hylian-shield/types';

export const usePhasesForm = (): IPhasesForm => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [input, setInput] = useState<Input>({} as Input);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [params, setParams] = useState<Params>({
    phases: [],
    phase_global_comment: '',
    deleted_records: {
      phases: [],
      deposits: [],
    },
  } as Params);

  const {id} = useParams();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data;

  const subject = useCurrentSubject(project?.project_type);

  useEffect(() => {
    if (!project) return;

    const {phases, phase_global_comment} = project;

    setParams({
      ...params,
      phases: orderingPhases(phases),
      phase_global_comment,
    });
  }, []);

  const [storeProjectPhases, {isLoading, error, data: response}] =
    useStoreProjectPhasesMutation();

  const serverError = parseValidationErrors(error);

  const shapePayload = (data: Params): StoreProjectPhasesRequest => {
    const {deleted_records, phase_global_comment} = data;

    const phases = data.phases.map(item => {
      return {
        ...item,
        deposits: item.deposits.map(child => {
          return {
            ...child,
            invoice_id: child.invoice ? child.invoice.id : '',
          };
        }),
      };
    });

    return {
      id,
      phases,
      phase_global_comment,
      deleted_records,
    };
  };

  const onDragEnd = (result: DropResult) => {
    const {destination, source} = result;
    if (!destination) return;

    const orderUpdated = [...params.phases];

    orderUpdated[source.index] = {
      ...orderUpdated[source.index],
      order: destination.index,
    };

    const reorderedPhases = arrayReorder(
      orderUpdated,
      source.index,
      destination.index
    ).map((item, index) => ({
      ...item,
      order: index,
    }));

    setParams({
      ...params,
      phases: reorderedPhases,
    });

    setIsDragging(false);
  };

  const handleSubmit = async () => {
    const result = await storeProjectPhases(shapePayload(params));

    // Update form parameters if data is received from the server
    if ('data' in result) {
      const project = result?.data.data;
      dispatch(toast('success', t('common.changesCommited')));

      const {phases, phase_global_comment} = project;

      setParams({
        phases: orderingPhases(phases),
        phase_global_comment,
        deleted_records: {
          phases: [],
          deposits: [],
        },
      });
    }
  };

  const handleChangeGlobalComment: HandleChangeGlobalComment =
    phase_global_comment => {
      setParams({
        ...params,
        phase_global_comment,
      });
    };

  const handleInputChange: HandleInputChange = val => {
    if (typeof val === 'string')
      return setInput({...input, other_type_name: val});

    return setInput(val);
  };

  const handleAddInput: HandleAddInput = () => {
    if (!input) return;

    const newPhases = [...params.phases];

    const entry = {
      id: makeId(),
      type: input.id,
      order: newPhases.length,
      deposits: [],
    } as IPhase;

    if (input.other_type_name) entry.other_type_name = input.other_type_name;
    newPhases.push(entry as IPhase);

    setParams({...params, phases: newPhases});
    setInput({} as Option);
  };

  const handleRemoveInput: HandleRemoveInput = index => {
    const updatedPhases = [...params.phases];
    const deleted_records = {...params.deleted_records};

    // check if item exists
    if (project?.phases.find(el => el.id === updatedPhases[index].id))
      deleted_records.phases?.push(updatedPhases[index].id);

    updatedPhases.splice(index, 1);

    setParams({
      ...params,
      phases: updatedPhases,
      deleted_records,
    });
  };

  const handleAddDeposit: HandleAddDeposit = index => {
    const updatedPhases = [...params.phases];

    updatedPhases[index] = {
      ...updatedPhases[index],
      deposits: [
        ...updatedPhases[index].deposits,
        {
          id: makeId(),
          fees: 0,
          honoraria: 0,
        },
      ] as IDeposit[],
    } as IPhase;

    setParams({...params, phases: updatedPhases});
  };

  const handleChangeDeposit: HandleChangeDeposit = (
    parentIndex,
    index,
    key,
    value
  ) => {
    const updatedPhases = [...params.phases];
    const updatedDeposits = [...updatedPhases[parentIndex].deposits];

    updatedDeposits[index] = {
      ...updatedDeposits[index],
      [key]: value,
    };

    updatedPhases[parentIndex] = {
      ...updatedPhases[parentIndex],
      deposits: updatedDeposits,
    };

    setParams({...params, phases: updatedPhases});
  };

  const handleRemoveDeposit: HandleRemoveDeposit = (parentIndex, index) => {
    const updatedPhases = [...params.phases];
    const updatedDeposits = [...updatedPhases[parentIndex].deposits];

    const deleted_records = {...params.deleted_records};

    // check if item exists
    if (typeof updatedDeposits[index].id === 'number')
      deleted_records.deposits?.push(updatedDeposits[index].id);

    updatedDeposits.splice(index, 1);

    updatedPhases[parentIndex] = {
      ...updatedPhases[parentIndex],
      deposits: updatedDeposits,
    };

    setParams({...params, phases: updatedPhases});
  };

  const options = {
    phases: useEnumAsOption(ProjectPhasesEnum, 'editProjectPhaseSection'),
  };

  const getPhaseProgression: GetPhaseProgression = deposits => {
    const length = deposits.filter(el => el.paid_on).length;

    const percentage = (length * 100) / deposits.length;
    const value = percentage ? percentage : 0;

    const text = `${length}/${deposits.length}`;
    return {value, text};
  };

  const defaultOpenedAccordion = useMemo(() => {
    return params.phases
      .filter(el => el.deposits.length > 0)
      .map((el, index) => index.toString());
  }, [params.phases]);

  return {
    input,
    options,
    params,
    isLoading,
    defaultOpenedAccordion,
    validationErrors: serverError.errors,
    isDragging,
    subject,
    setIsDragging,
    onDragEnd,
    handleChangeGlobalComment,
    getPhaseProgression,
    handleAddInput,
    handleInputChange,
    handleRemoveInput,
    handleAddDeposit,
    handleChangeDeposit,
    handleRemoveDeposit,
    handleSubmit,
  };
};

interface Input extends Option {
  other_type_name?: string;
}

export interface IPhasesForm {
  input: Input;
  params: Params;
  options: {
    phases: EnumMapping<typeof ProjectPhasesEnum>[];
  };
  isLoading: boolean;
  defaultOpenedAccordion: string[];
  validationErrors: ValidationErrors;
  isDragging: boolean;
  subject: Subject;
  setIsDragging: (arg: boolean) => void;
  onDragEnd: (result: DropResult) => void;
  handleChangeGlobalComment: HandleChangeGlobalComment;
  getPhaseProgression: GetPhaseProgression;
  handleAddInput: HandleAddInput;
  handleInputChange: HandleInputChange;
  handleRemoveInput: HandleRemoveInput;
  handleAddDeposit: HandleAddDeposit;
  handleChangeDeposit: HandleChangeDeposit;
  handleRemoveDeposit: HandleRemoveDeposit;
  handleSubmit: HandleSubmit;
}

type Params = Pick<Project, 'phases' | 'phase_global_comment'> & {
  deleted_records: {
    phases: ObjectId[];
    deposits: ObjectId[];
  };
};
