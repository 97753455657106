import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  pageTitle: () => __(translations.createProductWebPage.pageTitle, 'New web product'),
  products: () => __(translations.createProductWebPage.products, 'Products'),
  masterProductsWeb: () => __(translations.createProductWebPage.masterProductsWeb, 'Master products web'),
  create: () => __(translations.createProductWebPage.create, 'New web product'),
  name: () => __(translations.createProductWebPage.name, 'Name ({{lang}})'),
  knowledge: () => __(translations.createProductWebPage.knowledge, 'Knowledge ({{lang}})'),
  size: () => __(translations.createProductWebPage.size, 'Size ({{lang}})'),
  reassuranceInformations: () => __(translations.createProductWebPage.reassuranceInformations, 'Reassurance information ({{lang}})'),
  addSize: () => __(translations.createProductWebPage.addSize, 'Add size'),
  productWebCreated: () => __(translations.createProductWebPage.productWebCreated, 'Product web has been created succesfully'),
};
