import {DeleteResourceAction, DestroyResourceUrlMap} from './types';
import {toast} from 'react-toastify';

export const makeDeleteResourceUrl = (action: DeleteResourceAction): string => {
  const urlGenerator = urls[action.name];

  if (typeof urlGenerator === 'function') {
    return urlGenerator(action);
  }
  return `url-undefined/${action.resourceId}`;
};

/**
 * All resource removal endpoints
 *
 */
const urls: DestroyResourceUrlMap = {
  users: action => `users/${action.resourceId}`,
  'quotation-product-option': action =>
    `quotations/${action.otherParams.doc_id}/commercial-products/${action.otherParams.item_id}/commercial-product-option/${action.resourceId}`,
  'order-product-option': action =>
    `orders/${action.otherParams.doc_id}/commercial-products/${action.otherParams.item_id}/commercial-product-option/${action.resourceId}`,
  'trashed-users': action => `trashed/users/${action.resourceId}/force-destroy`,
  variants: action => `product-variants/${action.resourceId}`,
  'quotation-product': action =>
    `quotations/${action.otherParams.doc_id}/commercial-products/${action.resourceId}`,
  'order-product': action =>
    `orders/${action.otherParams.doc_id}/commercial-products/${action.resourceId}`,
  directories: action => `directories/${action.resourceId}`,
  'directory-categories': action => `directory-categories/${action.resourceId}`,
  'directory-families': action => `directory-families/${action.resourceId}`,
  interactions: action =>
    `directories/${action.otherParams.directoryId}/interactions/${action.resourceId}`,
  administrators: action => `administrators/${action.resourceId}`,
  products: action => `products/${action.resourceId}`,
  families: action => `product-families/${action.resourceId}`,
  address: action =>
    `directories/${action.otherParams.directoryId}/addresses/${action.resourceId}`,
  ecotaxes: action => `ecotaxes/${action.resourceId}`,
  'invoice-product': action =>
    `invoices/${action.otherParams.doc_id}/commercial-products/${action.resourceId}`,
  'invoice-product-option': action =>
    `invoices/${action.otherParams.doc_id}/commercial-products/${action.otherParams.item_id}/commercial-product-option/${action.resourceId}`,
  payments: action => `payments/${action.resourceId}`,
  'stock-locations': action => `stock-locations/${action.resourceId}`,
  'stock-location-categories': action =>
    `stock-location-categories/${action.resourceId}`,
  'stock-sub-locations': action => `stock-sub-locations/${action.resourceId}`,
  projects: action => `projects/${action.resourceId}`,
  'stock-products-dispute-sav': action =>
    `stock/serial-numbers/${action.resourceId}`,
  'product-webs': action => `product-webs/${action.resourceId}`,
};

export const toastConfig = {
  position: toast.POSITION.TOP_RIGHT,
};
