import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateProductWeb = api.injectEndpoints({
  endpoints: builder => ({
    updateProductWeb: builder.mutation<Response, UpdateProductWebRequest>({
      query: body => ({
        method: 'PUT',
        url: `/product-webs/${body.id}`,
        body: body,
      }),
    }),
  }),
});

export type UpdateProductWebRequest = {
  id?: ObjectId;
  name_fr: string;
  name_en: string;
  skill_fr: string;
  skill_en: string;
  reassurance_information_fr: string;
  reassurance_information_en: string;
  sizes?: {
    id: ObjectId;
    value: {
      fr: string;
      en: string;
    };
  }[];
  deleted_records?: ObjectId[];
};

type Response = ResponseData<UpdateProductWebRequest>;

export const {useUpdateProductWebMutation} = updateProductWeb;
