import {STATUS} from 'constants/status';
import {ProductAttachment} from './attachements';
import {ExportPdf} from './export-pdf';
import {Manufacturer} from './manufacturer';
import {Media} from './media';
import {Model} from './model';
import {ObjectId} from './object-id';
import {Option} from './option';
import {Product} from './product';
import {ProductComponent} from './product-component';
import {ProductCustomization} from './product-customization';
import {ProductDimension} from './product-dimension';
import {ProductManifactureTime} from './product-manifacture-time';
import {ProductTranslation} from './product-translation';
import {Situation} from './situation';
import {EcoTax} from 'features/acounting/types/eco-tax';
import {ProductWeb} from './product-web';
import ProductVariantWeb from './product-variant-web';

export interface ProductVariant extends Model {
  attachements: ProductAttachment[];
  catalog: boolean;
  color_id: ObjectId;
  colors?: Option[];
  comment: string;
  product_familles?: string;
  component: ProductComponent;
  components: ProductVariant[];
  cover?: Media;
  customization_options: ProductCustomization[];
  depth: string;
  description: string;
  dimensions: string;
  finition: string;
  has_situations: boolean;
  height: string;
  is_archived: boolean;
  is_blocked: boolean;
  is_deleted: boolean;
  stock_min: string;
  stock_max: string;
  is_limited_edition: boolean;
  is_old_collection: boolean;
  self_composed: boolean;
  limited_edition_count: string;
  manufacturers: Option[];
  matter_id: ObjectId;
  matters?: Option[];
  creation_date?: string;
  ecotax: EcoTax;
  name: string;
  package_dimension: ProductDimension[];
  package_dimensions: ProductDimension[];
  parent_id: ObjectId;
  pdf_links: ExportPdf;
  price_situations: Situation[];
  product_id: ObjectId;
  product_variant_category: Option;
  product: Product;
  production_times: ProductManifactureTime[];
  purchase_price: number;
  selling_price_vat_exc: number;
  selling_price: number;
  situations_count: number;
  sku: string;
  stock_available: number;
  stock_total: number;
  suppliers: Record<ObjectId, Manufacturer>;
  translations: ProductTranslation[];
  width: string;
  diameter?: string;
  is_transferable?: boolean;
  product_variant_web: ProductVariantWeb;
  is_published: boolean;
}

export interface SerialNumber {
  id: ObjectId;
  code: string;
  quantity: number;
  commercial_product: {
    id: ObjectId;
    quantity: number;
  };
  order: {
    id: ObjectId;
    order_ref: string;
    client_name: string;
  };
  status: SERIAL_NUMBER_STATUS;
  stock_sub_location: {
    id: ObjectId;
    name: string;
  };
  pdf_links: {
    label: string;
  };
}

export enum SERIAL_NUMBER_STATUS {
  OPENED = STATUS.OPENED,
  PENDING = STATUS.PENDING,
  VALIDATED = STATUS.VALIDATED,
  WAITING_FOR_DELIVERY = STATUS.WAITING_FOR_DELIVERY,
  DEDUCTED = STATUS.DEDUCTED,
  CANCELED = STATUS.CANCELED,
}
