import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';
import {CommercialDocument} from '../../types/commercial-document';
import {updateCachedOrderFromResource} from 'features/commercial/orders/store/endpoints/show-order';
import {updateCachedInvoiceFromResource} from 'features/commercial/invoices/store/endpoints/show-invoice';
import RelatedDocument from 'types/related-document';

const saveStockMovement = api.injectEndpoints({
  endpoints: builder => ({
    saveStockMovement: builder.mutation<Response, StockMovementRequest>({
      query: params => {
        const movementMap = params.movements ?? {};
        let url = '';
        const minType = params.type?.toLocaleLowerCase();
        let doc = params.doc;
        if (params.doc?.document_type === 'SUPPLIER') {
          url = `orders/${doc?.id}/commercial-products/${params.commercial_product_id}/stock-movements`;
        } else {
          url = `invoices/${doc?.id}/commercial-products/${params.commercial_product_id}/${minType}-stock-movements`;
        }

        const movement_infos = Object.values(movementMap).map(x => {
          let object: any = {
            stock_location_start_id: x.stock_location_start?.id,
            stock_location_arrival_id: x.stock_location_arrival?.id,
            stock_sub_location_start_id: x.stock_sub_location_start?.id,
            stock_sub_location_arrival_id: x.stock_sub_location_arrival?.id,
            quantity: x.quantity,
            comment: x.comment,
          };

          if (params.type === 'IN') {
            const {client_order_related_doc, stock_order_related_doc} = x;

            if (stock_order_related_doc) {
              object = {
                ...object,
                stock_order_id: stock_order_related_doc?.model_id,
                stock_commercial_product_id:
                  stock_order_related_doc?.commercial_product_id,
              };
            }

            if (client_order_related_doc) {
              object = {
                ...object,
                client_order_id: client_order_related_doc?.model_id,
                client_commercial_product_id:
                  client_order_related_doc?.commercial_product_id,
              };
            }
          }

          return object;
        });
        return {
          method: 'POST',
          url,
          body: {
            type: params.type,
            commercial_product_id: params.commercial_product_id,
            movement_infos,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const result = (await queryFulfilled).data?.data;
          if (result.document_type === 'SUPPLIER') {
            // @ts-ignore
            dispatch(updateCachedOrderFromResource(result));
          } else {
            // @ts-ignore
            dispatch(updateCachedInvoiceFromResource(result));
          }
        } catch {}
      },
    }),
  }),
});

export type StockMovementRequest = {
  id?: ObjectId;
  variantId?: ObjectId;
  type?: 'IN' | 'OUT' | 'TRANSFER';
  doc?: CommercialDocument;
  commercial_product_id?: ObjectId;
  movements?: Record<ObjectId, StockMovement>;
};

export type StockMovement = {
  id: ObjectId;
  stock_location_start?: Option;
  stock_location_arrival?: Option;
  stock_sub_location_start?: Option;
  stock_sub_location_arrival?: Option;
  client_order_related_doc?: RelatedDocument;
  stock_order_related_doc?: RelatedDocument;
  quantity?: number;
  comment?: string;
};

type Response = ResponseData<CommercialDocument>;

export const {useSaveStockMovementMutation} = saveStockMovement;
