import { Language } from 'components/text-editor/multi-lang/types';
import {uniqueId} from 'lodash';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {ValidationErrors} from 'services/api/types';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  useStoreProductWebMutation,
} from 'store/production/endpoints/store-product-web';
import toast from 'store/ui/actions/toast';
import {ObjectId} from 'types/object-id';

type Params = {
  name_fr: ''
  name_en: ''
  skill_fr: ''
  skill_en: ''
  reassurance_information_fr: ''
  reassurance_information_en: ''
  sizes: {
    id: ObjectId;
    value: {
      fr: string;
      en: string;
    };
  }[];
};

export interface IProductWebForm {
  params: Params;
  validationErrors: ValidationErrors;
  isLoading: boolean;
  setParams: (arg: Params) => void;
  handleAddSize: () => void;
  handleChangeSize: (id: ObjectId, value: string, key: 'fr' | 'en') => void;
  handleRemoveSize: (id: ObjectId) => void;
  handleSubmit: () => void;
}

export const useCreateProductWeb = (): IProductWebForm => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [storeProductweb, {isLoading, error}] = useStoreProductWebMutation();
  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState<Params>({
    name_fr: '',
    name_en: '',
    skill_fr: '',
    skill_en: '',
    reassurance_information_fr: '',
    reassurance_information_en: '',
    sizes: [],
  });

  const handleSubmit = async () => {
    const payload = {
      ...params,
      sizes: params.sizes.map(el => ({
        fr: el.value?.fr,
        en: el.value?.en,
      })),
    };

    const result = await storeProductweb(payload);

    if ('data' in result) {
      dispatch(toast('success', t('createProductWebPage.productWebCreated')));
      navigate(`/dashboard/master-products-web`);
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const handleAddSize = () => {
    setParams({
      ...params,
      sizes: [
        ...params.sizes,
        {
          id: uniqueId(),
          value: {
            fr: '',
            en: '',
          },
        },
      ],
    });
  };

  const handleChangeSize = (id: ObjectId, value: string, key: Language) => {
    const updatedSize = [...params.sizes];

    const target = updatedSize.findIndex(el => id === el.id);
    if (target === -1) return;

    updatedSize[target] = {
      ...updatedSize[target],
      value: {
        ...updatedSize[target].value,
        [key]: value,
      },
    };

    setParams({...params, sizes: updatedSize});
  };

  const handleRemoveSize = (id: ObjectId) => {
    const updatedSize = [...params.sizes].filter(el => el.id !== id);
    setParams({
      ...params,
      sizes: updatedSize,
    });
  };

  return {
    params,
    validationErrors: serverError.errors,
    isLoading,
    setParams,
    handleAddSize,
    handleChangeSize,
    handleRemoveSize,
    handleSubmit,
  };
};
