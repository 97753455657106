import React, {FC, useState} from 'react';
import {ILocalizedTextEditor, Language} from './types';
import {TextEditor} from '..';
import {
  Container,
  LanguageSelector,
  SelectorWrapper,
} from './styled';
import i18next from 'i18next';

export const LocalizedTextEditor: FC<ILocalizedTextEditor> = ({
  value,
  label,
  errors,
  onChange,
  languages = i18next.languages,
  required,
  id,
  ...rest
}) => {
  const [currentLanguage, setCurrentLanguage] = useState<any>(
    languages?.length > 0 ? languages[0] : i18next.language
  );

  const handleChange = (newValue: string) => {
    onChange(newValue, currentLanguage);
  };

  return (
    <Container>
      {/* Language Selector */}
      <SelectorWrapper>
        <LanguageSelector
          name="languages"
          id="languages"
          value={currentLanguage}
          onChange={e => setCurrentLanguage(e.target.value)}
        >
          {languages.map(lang => (
            <option key={lang} value={lang}>
              {lang.toUpperCase()}
            </option>
          ))}
        </LanguageSelector>
      </SelectorWrapper>

      {/* Input / Editor */}
      <TextEditor
        id={id(currentLanguage)}
        value={value(currentLanguage)}
        label={label(currentLanguage)}
        errors={errors(currentLanguage)}
        onChange={handleChange}
        {...rest}
      />
    </Container>
  );
};
