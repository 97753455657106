import React from 'react';
import columns from './columns';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Col, Container} from 'reactstrap';
import Table from 'features/table';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Can from 'features/hylian-shield/can';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {Link} from 'react-router-dom';

const MasterProductsWebPage = () => {
  const {t} = useTranslation();
  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t('common.products'), path: '/dashboard/products'},
            {title: t(...messages.masterProductsWeb()), path: '#'},
          ]}
        />
        <Table columns={columns} url="product-webs" name="product-webs" />

        <Col md={3}>
          <AppBarActions>
            <Can action="administration" subject="products">
              <Link
                to="/dashboard/master-products-web/create"
                className="btn btn-important"
              >
                <Icon name="plus" className="me-1" size={15} />
                {t(...messages.addMasterProductWeb())}
              </Link>
            </Can>
          </AppBarActions>
        </Col>
      </Container>
    </div>
  );
};

export default MasterProductsWebPage;
