import {updateCachedVariantFromResource} from './get-product-variants';
import {updateCachedVariantDetailsFromResource} from './show-variant';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductDimension} from 'types/product-dimension';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const updateVariantInfo = api.injectEndpoints({
  endpoints: builder => ({
    updateVariantInfo: builder.mutation<Response, VariantInfoRequest>({
      query: params => ({
        method: 'PUT',
        url: `/product-variants/${params.variant}/general-info`,
        body: {
          catalog: params.catalog,
          color_id: params.colors?.map(x => x.id),
          comment: params.comment,
          deleted_records: params.deleteRecords,
          depth: params.depth,
          diameter: params.diameter,
          description: params.description,
          ecotax_id: params.ecotax?.id,
          finition: params.finition,
          creation_date: params.creation_date,
          stock_min: params.stock_min,
          stock_max: params.stock_max,
          height: params.height,
          is_limited_edition: params.is_limited_edition,
          limited_edition_count: params.limited_edition_count,
          matter_id: params.matters?.map(x => x.id),
          name: params.name,
          package_dimensions: transformDimensions(
            params.package_dimensions ?? {}
          ),
          product_id: params.id,
          product_variant_category_id: params.product_variant_category?.id,
          variant: params.variant,
          width: params.width,
          self_composed: params.self_composed || params.hasSelfComposedParent,
          is_transferable: params.is_transferable
        },
      }),
      async onQueryStarted({}, {dispatch, queryFulfilled}) {
        try {
          const res = (await queryFulfilled).data.data;
          dispatch(updateCachedVariantFromResource(res));
          dispatch(updateCachedVariantDetailsFromResource(res));
        } catch {}
      },
    }),
  }),
});

export type VariantInfoRequest = {
  id?: ObjectId;
  variant?: ObjectId;
  sku?: string;
  depth?: string;
  diameter?: string;
  product_variant_category?: Option;
  width?: string;
  height?: string;
  finition?: string;
  creation_date?: string;
  stock_min?: string;
  stock_max?: string;
  name?: string;
  comment?: string;
  is_limited_edition?: boolean;
  limited_edition_count?: string;
  colors?: Option[];
  matters?: Option[];
  catalog?: boolean;
  is_transferable?: boolean;
  ecotax?: Option;
  description?: string;
  deleteRecords?: ObjectId[];
  hasSelfComposedParent?: boolean;
  self_composed?: boolean;
  package_dimensions?: Record<ObjectId, ProductDimension>;
};

type Response = ResponseData<ProductVariant>;

export const {useUpdateVariantInfoMutation} = updateVariantInfo;

const transformDimensions = (
  package_dimensions: Record<ObjectId, ProductDimension>
): any[] => {
  return Object.values(package_dimensions).map(x => {
    return {
      ...x,
      depth: Number(x.depth),
      height: Number(x.height),
      net_weight: Number(x.net_weight),
      volume: Number(x.volume),
      weight: Number(x.weight),
      width: Number(x.width),
    };
  });
};
