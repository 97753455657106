import React from 'react';
import AttachmentsTab from '../variant/attachments';
import CostsTab from '../variant/costs';
import GeneralTab from '../variant/general';
import LinkedResourcesTab from '../variant/linked-resources';
import ManifactureTimeTab from '../variant/manifacture-time';
import SerialNumberTab from '../variant/serial-number';
import StockTab from '../variant/stock';
import TranslationsTab from '../variant/translations';
import ComponentsTab from '../variant/variant-components';
import WebTab from '../variant/web';
import {Routes, Route, Navigate} from 'react-router';

const VariantRoutes = () => {
  return (
    <Routes>
      <Route path="/attachments" element={<AttachmentsTab />} />
      <Route path="/components" element={<ComponentsTab />} />
      <Route path="/manifacture-time" element={<ManifactureTimeTab />} />
      <Route path="/costs" element={<CostsTab />} />
      <Route path="/general" element={<GeneralTab />} />
      <Route path="/linked-resources" element={<LinkedResourcesTab />} />
      <Route path="/serial-number" element={<SerialNumberTab />} />
      <Route path="/stock" element={<StockTab />} />
      <Route path="/translations" element={<TranslationsTab />} />
      <Route path={`/web`} element={<WebTab />} />
      <Route path={`/`} element={<Navigate to="general" />} />
    </Routes>
  );
};

export default VariantRoutes;
