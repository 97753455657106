import styled from "styled-components";

// Styled Components
export const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
`;

export const SelectorWrapper = styled.div`
  position: relative;
  top: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const LanguageSelector = styled.select`
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
`;