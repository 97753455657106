import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  pageTitle: () => __(translations.updateProductWebPage.pageTitle, 'Edit web product'),
  products: () => __(translations.updateProductWebPage.products, 'Products'),
  masterProductsWeb: () => __(translations.updateProductWebPage.masterProductsWeb, 'Master products web'),
  create: () => __(translations.updateProductWebPage.create, 'New web product'),
  name: () => __(translations.updateProductWebPage.name, 'Name ({{lang}})'),
  knowledge: () => __(translations.updateProductWebPage.knowledge, 'Knowledge ({{lang}})'),
  size: () => __(translations.updateProductWebPage.size, 'Size ({{lang}})'),
  addSize: () => __(translations.updateProductWebPage.addSize, 'Add size'),
  reassuranceInformations: () => __(translations.updateProductWebPage.relevanceInformations, 'Reassurance information ({{lang}})'),
  productWebCreated: () => __(translations.updateProductWebPage.productWebUpdated, 'Product web has been updated succesfully'),
};
